<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab
        :to="{ name: 'projectIssueStatistics', query: this.projectIssueStatisticsFilterParams }"
        >{{ $t('projects.projects_issues') }}</v-tab
      >
      <v-tab
        :to="{ name: 'employeeIssueStatistics', query: this.employeeIssueStatisticsFilterParams }"
        >{{ $t('projects.employees_issues') }}</v-tab
      >
      <v-tab
        :to="{ name: 'issueCreatedByStatistics', query: this.issueCreatedByStatisticsFilterParams }"
        >{{ $t('projects.created_issue_amounts') }}</v-tab
      >

      <v-tabs-items>
        <router-view />
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProjectsTabs',

  computed: {
    ...mapState('projectIssueStatistics', ['projectIssueStatisticsFilterParams']),
    ...mapState('employeeIssueStatistics', ['employeeIssueStatisticsFilterParams']),
    ...mapState('projectIssueCreatedByStatistics', ['issueCreatedByStatisticsFilterParams']),
  },
};
</script>

<style scoped></style>
